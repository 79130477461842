import { ApplicationRef, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInjector } from './modules/core/base/AppInjector';
import { AppEntryComponent } from './views/app-entry/app-entry.component';
import { AllJobOpeningsComponent } from './views/career-module/all-job-openings/all-job-openings.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { FinancialComplianceComponent } from './views/financial-compliance/financial-compliance.component';
import { HomeComponent } from './views/home/home.component';
import { HumanResourcesComponent } from './views/human-resources/human-resources.component';
import { JobDetailsComponent } from './views/job-details/job-details.component';
import { OurValuesComponent } from './views/our-values/our-values.component';
import { BusinessDigitalTransformationComponent } from './views/services-module/business-digital-transformation/business-digital-transformation.component';
import { LearningServicesComponent } from './views/services-module/learning-services/learning-services.component';
import { ProductEngineeringComponent } from './views/services-module/product-engineering/product-engineering.component';
import { SalesforcePracticeComponent } from './views/services-module/salesforce-practice/salesforce-practice.component';
import { TechnologyConsultingComponent } from './views/services-module/technology-consulting/technology-consulting.component';
import { BreadcrumbComponent } from './views/sharedviews/breadcrumb/breadcrumb.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './views/terms-conditions/terms-conditions.component';
import { ApplyFormComponent } from './views/sharedviews/apply-form/apply-form.component';
import { ShareOnComponent } from './views/sharedviews/share-on/share-on.component';
import { DotNetDeveloperComponent } from './views/dot-net-developer/dot-net-developer.component';
import { JavaDeveloper1Component } from './views/java-developer1/java-developer1.component';
import { JavaDeveloper2Component } from './views/java-developer2/java-developer2.component';
import { ChaosResiliencyTestEngineerComponent } from './views/chaos-resiliency-test-engineer/chaos-resiliency-test-engineer.component';
import { BusinessAnalystComponent } from './views/business-analyst/business-analyst.component';
import { DotNetTechnicalArchitectComponent } from './views/dot-net-technical-architect/dot-net-technical-architect.component';
import { TraineeProjectManagerComponent } from './views/trainee-project-manager/trainee-project-manager.component';
import { TraineeDataAnalystComponent } from './views/trainee-data-analyst/trainee-data-analyst.component';
import { CustomerServiceAssociateComponent } from './views/customer-service-associate/customer-service-associate.component';
import { ELearningDeveloperComponent } from './views/e-learning-developer/e-learning-developer.component';
import { FooterComponent } from './views/sharedviews/footer/footer.component';
import { HeaderComponent } from './views/sharedviews/header/header.component';
import { BackendComponent } from './views/technology-module/backend/backend.component';
import { FrontendComponent } from './views/technology-module/frontend/frontend.component';
import { MobilityComponent } from './views/technology-module/mobility/mobility.component';
import { HttpClientModule } from '@angular/common/http';
import { ShareOnDetailsComponent } from './views/sharedviews/share-on-details/share-on-details.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { BlogsComponent } from './views/blogs/blogs.component';
import { BlogsDetails1Component } from './views/blogs-pages/blogs-details1/blogs-details1.component';
import { BlogsDetails2Component } from './views/blogs-pages/blogs-details2/blogs-details2.component';
import { BlogsDetails3Component } from './views/blogs-pages/blogs-details3/blogs-details3.component';
import { BlogsDetails4Component } from './views/blogs-pages/blogs-details4/blogs-details4.component';
import { BlogsDetails5Component } from './views/blogs-pages/blogs-details5/blogs-details5.component';
import { BlogsDetails6Component } from './views/blogs-pages/blogs-details6/blogs-details6.component';
import { BlogsDetails7Component } from './views/blogs-pages/blogs-details7/blogs-details7.component';
import { BlogsDetails8Component } from './views/blogs-pages/blogs-details8/blogs-details8.component';
import { BlogsDetails9Component } from './views/blogs-pages/blogs-details9/blogs-details9.component';
import { BlogsDetails10Component } from './views/blogs-pages/blogs-details10/blogs-details10.component';
import { BlogsDetails11Component } from './views/blogs-pages/blogs-details11/blogs-details11.component';
import { BlogsDetails12Component } from './views/blogs-pages/blogs-details12/blogs-details12.component';
import { BlogsDetails13Component } from './views/blogs-pages/blogs-details13/blogs-details13.component';
import { BlogsDetails14Component } from './views/blogs-pages/blogs-details14/blogs-details14.component';
import { BlogsDetails15Component } from './views/blogs-pages/blogs-details15/blogs-details15.component';
import { BlogsDetails16Component } from './views/blogs-pages/blogs-details16/blogs-details16.component';
import { BlogsDetails17Component } from './views/blogs-pages/blogs-details17/blogs-details17.component';
import { BlogsDetails18Component } from './views/blogs-pages/blogs-details18/blogs-details18.component';
import { BlogsDetails19Component } from './views/blogs-pages/blogs-details19/blogs-details19.component';
import { BlogsDetails20Component } from './views/blogs-pages/blogs-details20/blogs-details20.component';
import { BlogsDetails21Component } from './views/blogs-pages/blogs-details21/blogs-details21.component';
import { BlogsDetails22Component } from './views/blogs-pages/blogs-details22/blogs-details22.component';
import { BlogsDetails23Component } from './views/blogs-pages/blogs-details23/blogs-details23.component';
import { BlogsDetails24Component } from './views/blogs-pages/blogs-details24/blogs-details24.component';
import { BlogsDetails25Component } from './views/blogs-pages/blogs-details25/blogs-details25.component';
import { BlogsDetails26Component } from './views/blogs-pages/blogs-details26/blogs-details26.component';
import { BlogsDetails27Component } from './views/blogs-pages/blogs-details27/blogs-details27.component';
import { BlogsDetails28Component } from './views/blogs-pages/blogs-details28/blogs-details28.component';
import { BlogsDetails29Component } from './views/blogs-pages/blogs-details29/blogs-details29.component';
import { BlogsDetails30Component } from './views/blogs-pages/blogs-details30/blogs-details30.component';
import { BlogsDetails31Component } from './views/blogs-pages/blogs-details31/blogs-details31.component';
import { BlogsDetails32Component } from './views/blogs-pages/blogs-details32/blogs-details32.component';
import { BlogsDetails33Component } from './views/blogs-pages/blogs-details33/blogs-details33.component';
import { BlogsDetails34Component } from './views/blogs-pages/blogs-details34/blogs-details34.component';
import { BlogsDetails35Component } from './views/blogs-pages/blogs-details35/blogs-details35.component';
import { BlogsDetails36Component } from './views/blogs-pages/blogs-details36/blogs-details36.component';
import { BlogsDetails37Component } from './views/blogs-pages/blogs-details37/blogs-details37.component';
import { BlogsDetails38Component } from './views/blogs-pages/blogs-details38/blogs-details38.component';
import { BlogsDetails39Component } from './views/blogs-pages/blogs-details39/blogs-details39.component';
import { BlogsDetails40Component } from './views/blogs-pages/blogs-details40/blogs-details40.component';
import { BlogsDetails41Component } from './views/blogs-pages/blogs-details41/blogs-details41.component';
import { BlogsDetails42Component } from './views/blogs-pages/blogs-details42/blogs-details42.component';
import { BlogsDetails43Component } from './views/blogs-pages/blogs-details43/blogs-details43.component';
import { BlogsDetails44Component } from './views/blogs-pages/blogs-details44/blogs-details44.component';
import { BlogsDetails45Component } from './views/blogs-pages/blogs-details45/blogs-details45.component';
import { BlogsDetails46Component } from './views/blogs-pages/blogs-details46/blogs-details46.component';
import { BlogsDetails47Component } from './views/blogs-pages/blogs-details47/blogs-details47.component';
import { BlogsDetails48Component } from './views/blogs-pages/blogs-details48/blogs-details48.component';
import { BlogsDetails49Component } from './views/blogs-pages/blogs-details49/blogs-details49.component';
import { BlogsDetails50Component } from './views/blogs-pages/blogs-details50/blogs-details50.component';
import { BlogsDetails51Component } from './views/blogs-pages/blogs-details51/blogs-details51.component';
import { BlogsDetails52Component } from './views/blogs-pages/blogs-details52/blogs-details52.component';
import { BlogsDetails53Component } from './views/blogs-pages/blogs-details53/blogs-details53.component';
import { BlogsDetails54Component } from './views/blogs-pages/blogs-details54/blogs-details54.component';
import { BlogsDetails55Component } from './views/blogs-pages/blogs-details55/blogs-details55.component';
import { BlogsDetails56Component } from './views/blogs-pages/blogs-details56/blogs-details56.component';
import { BlogsDetails57Component } from './views/blogs-pages/blogs-details57/blogs-details57.component';
import { BlogsDetails58Component } from './views/blogs-pages/blogs-details58/blogs-details58.component';
import { MoreBlogsComponent } from './views/sharedviews/more-blogs/more-blogs.component';
import { EsgComponent } from './views/esg/esg.component';
import { TermsOfUseComponent } from './views/terms-of-use/terms-of-use.component';
import { CookiesPolicyComponent } from './views/cookies-policy/cookies-policy.component';
import { CookiesConsentComponent } from './views/cookies-consent/cookies-consent.component';
import { AdobeExperienceManagerComponent } from './views/adobe-experience-manager/adobe-experience-manager.component';
import { PopupModalComponent } from './views/sharedviews/popup-modal/popup-modal.component';
import { ServiceNowComponent } from './views/technology-module/service-now/service-now.component';
import { HealthAndSafetyComponent } from './views/lms-pages/health-and-safety/health-and-safety.component';
import { HealthCareComponent } from './views/lms-pages/health-care/health-care.component';
import { InformationSecurityComponent } from './views/lms-pages/information-security/information-security.component';
import { LeadershipAndManagementComponent } from './views/lms-pages/leadership-and-management/leadership-and-management.component';
import { OtherCourseCataloguesComponent } from './views/sharedviews/other-course-catalogues/other-course-catalogues.component';
import { OurApproachSharedComponent } from './views/sharedviews/our-approach-shared/our-approach-shared.component';
import { ArticlesSharedComponent } from './views/sharedviews/articles-shared/articles-shared.component';
import { BigDataConsultantComponent } from './views/big-data-consultant/big-data-consultant.component';
import { FormComponent } from './views/sharedviews/form/form.component';
import { ExploreTechnologyComponent } from './views/sharedviews/explore-technology/explore-technology.component';
import { CaseStudy1Component } from './views/services-module/case-study1/case-study1.component';
import { CaseStudy2Component } from './views/services-module/case-study2/case-study2.component';
import { CaseStudy3Component } from './views/services-module/case-study3/case-study3.component';
import { CaseStudy4Component } from './views/services-module/case-study4/case-study4.component';
import { CaseStudy5Component } from './views/services-module/case-study5/case-study5.component';
import { CaseStudy6Component } from './views/services-module/case-study6/case-study6.component';
import { CaseStudy7Component } from './views/services-module/case-study7/case-study7.component';
import { CaseStudy8Component } from './views/services-module/case-study8/case-study8.component';
import { CaseStudy9Component } from './views/services-module/case-study9/case-study9.component';
import { CaseStudy10Component } from './views/services-module/case-study10/case-study10.component';
import { CaseStudiesComponent } from './views/services-module/case-studies/case-studies.component';
import { StaffAugmentationComponent } from './views/services-module/staff-augmentation/staff-augmentation.component';
import { SecOpsComponent } from './views/technology-module/sec-ops/sec-ops.component';
import { CloudComponent } from './views/technology-module/cloud/cloud.component';
import { DevOpsComponent } from './views/technology-module/dev-ops/dev-ops.component';
import { ReadMoreComponent } from './views/sharedviews/read-more/read-more.component';
import { CustomDevelopmentComponent } from './views/technology-module/custom-development/custom-development.component';
import { CareerComponent } from './views/career/career.component';
import { WhoWeAreComponent } from './views/who-we-are/who-we-are.component';
import { WhyWorkWithUsComponent } from './views/sharedviews/why-work-with-us/why-work-with-us.component';
import { GolangSoftwareEngineerComponent } from './views/golang-software-engineer/golang-software-engineer.component';
import { UiEngineersReactjsNodejsComponent } from './views/ui-engineers-reactjs-nodejs/ui-engineers-reactjs-nodejs.component';
import { SrEngineerDevopsComponent } from './views/sr-engineer-devops/sr-engineer-devops.component';
import { ProductOwnerUkComponent } from './views/product-owner-uk/product-owner-uk.component';
import { QuallityEngineerItComponent } from './views/quallity-engineer-it/quallity-engineer-it.component';
import { TestimonySliderComponent } from './views/testimony-slider/testimony-slider.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { BlogsTableOfContentWebComponent } from './views/blogs-pages/blogs-table-of-content-web/blogs-table-of-content-web.component';
import { BlogsTableOfContentMobileComponent } from './views/blogs-pages/blogs-table-of-content-mobile/blogs-table-of-content-mobile.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AppEntryComponent,
    HomeComponent,
    ContactUsComponent,
    BackendComponent,
    FrontendComponent,
    MobilityComponent,
    ProductEngineeringComponent,
    BusinessDigitalTransformationComponent,
    TechnologyConsultingComponent,
    SalesforcePracticeComponent,
    LearningServicesComponent,
    AllJobOpeningsComponent,
    OurValuesComponent,
    HumanResourcesComponent,
    FinancialComplianceComponent,
    JobDetailsComponent,
    BreadcrumbComponent,
    BreadcrumbComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    ApplyFormComponent,
    ShareOnComponent,
    DotNetDeveloperComponent,
    JavaDeveloper1Component,
    JavaDeveloper2Component,
    ChaosResiliencyTestEngineerComponent,
    BusinessAnalystComponent,
    DotNetTechnicalArchitectComponent,
    TraineeProjectManagerComponent,
    TraineeDataAnalystComponent,
    CustomerServiceAssociateComponent,
    ELearningDeveloperComponent,
    EsgComponent,
    ShareOnDetailsComponent,
    AdobeExperienceManagerComponent,
    BlogsComponent,
    BlogsDetails1Component,
    BlogsDetails2Component,
    BlogsDetails3Component,
    BlogsDetails4Component,
    BlogsDetails5Component,
    BlogsDetails6Component,
    BlogsDetails7Component,
    BlogsDetails8Component,
    BlogsDetails9Component,
    BlogsDetails10Component,
    BlogsDetails11Component,
    BlogsDetails12Component,
    BlogsDetails13Component,
    BlogsDetails14Component,
    BlogsDetails15Component,
    BlogsDetails16Component,
    BlogsDetails17Component,
    BlogsDetails18Component,
    BlogsDetails19Component,
    BlogsDetails20Component,
    BlogsDetails21Component,
    BlogsDetails22Component,
    BlogsDetails23Component,
    BlogsDetails24Component,
    BlogsDetails25Component,
    BlogsDetails26Component,
    BlogsDetails27Component,
    BlogsDetails28Component,
    BlogsDetails29Component,
    BlogsDetails30Component,
    BlogsDetails31Component,
    BlogsDetails32Component,
    BlogsDetails33Component,
    BlogsDetails34Component,
    BlogsDetails35Component,
    BlogsDetails36Component,
    BlogsDetails37Component,
    BlogsDetails38Component,
    BlogsDetails39Component,
    BlogsDetails40Component,

    BlogsDetails41Component,
    BlogsDetails42Component,
    BlogsDetails43Component,
    BlogsDetails44Component,
    BlogsDetails45Component,
    BlogsDetails46Component,
    BlogsDetails47Component,
    BlogsDetails48Component,
    BlogsDetails49Component,

    BlogsDetails50Component,
    BlogsDetails51Component,
    BlogsDetails52Component,
    BlogsDetails53Component,
    BlogsDetails54Component,
    BlogsDetails55Component,
    BlogsDetails56Component,
    BlogsDetails57Component,
    BlogsDetails58Component,

    MoreBlogsComponent,
    TermsOfUseComponent,
    CookiesPolicyComponent,
    CookiesConsentComponent,
    PopupModalComponent,
    ServiceNowComponent,
    HealthAndSafetyComponent,
    HealthCareComponent,
    InformationSecurityComponent,
    LeadershipAndManagementComponent,
    OtherCourseCataloguesComponent,
    OurApproachSharedComponent,
    ArticlesSharedComponent,
    BigDataConsultantComponent,
    FormComponent,
    ExploreTechnologyComponent,
    CaseStudy1Component,
    CaseStudy2Component,
    CaseStudy3Component,
    CaseStudy4Component,
    CaseStudy5Component,
    CaseStudy6Component,
    CaseStudy7Component,
    CaseStudy8Component,
    CaseStudy9Component,
    CaseStudy10Component,
    CaseStudiesComponent,
    StaffAugmentationComponent,
    SecOpsComponent,
    CloudComponent,
    DevOpsComponent,
    ReadMoreComponent,
    CustomDevelopmentComponent,
    CareerComponent,
    WhoWeAreComponent,
    WhyWorkWithUsComponent,
    GolangSoftwareEngineerComponent,
    UiEngineersReactjsNodejsComponent,
    SrEngineerDevopsComponent,
    ProductOwnerUkComponent,
    QuallityEngineerItComponent,
    TestimonySliderComponent,
    CustomDatePipe,
    BlogsTableOfContentWebComponent,
    BlogsTableOfContentMobileComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxJsonLdModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {

    AppInjector.injector = injector;
    // console.log(injector.get(CommonFunctionsService));
  }

  ngDoBootstrap(applicationRef: ApplicationRef) {
    applicationRef.bootstrap(AppComponent);

    //console.log(AppInjector.injector);
  }
}
