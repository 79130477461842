<div class="options-wrapper">
  <div class="mb-30">
    <p class="bold mb-10">Share On:</p>
    <div class="share-icons">
      <a role="button" (click)="showFacebookLink()"><img src="assets/images/icons/facebook-blue-icon.svg"
          alt="Facebook Icon - Skillikz" width="24" height="24"> <span>Facebook</span></a>
      <a role="button" (click)="showTwitterLink()"><img src="assets/images/icons/twitter-icon.svg" alt="Twitter Icon - Skillikz"
          width="24" height="24"> <span>Twitter</span></a>
      <a role="button" (click)="showLinkedinLink()"><img src="assets/images/icons/linkedin-blue-icon.svg"
          alt="Linkedin Icon - Skillikz" width="24" height="24"> <span>Linkedin</span></a>
    </div>
  </div>

  <div class="">
    <p class="bold mb-10">More Blogs</p>
    <ul class="list-red">
      <ng-container *ngFor="let item of selectedBlogs">
        <li><a [routerLink]="item.path">{{item.title}}</a></li>
      </ng-container>
      <!-- <li><a routerLink="/new-technology-investment-paradigm-roi-2024">The New Technology Investment
          Paradigm: Maximizing ROI in 2024</a></li>
      <li><a routerLink="/static-to-strategic-digital-transformation-guide">Static to Strategic: A Digital
          Transformation Guide</a></li>
      <li><a routerLink="/steering-digital-transformation-purpose-driven-leadership">Steering Digital
          Transformation with Purpose-Driven Leadership</a></li> -->
      <!-- <li><a href="#">[BlogHeaderName]</a></li> -->
    </ul>
  </div>
  <button type="button" (click)="sendToCal()" class="btn blue-light" aria-label="Quick Chat">Quick Chat</button>
</div>