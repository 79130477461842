<section class="section-inner-content topMargin">
  <div class="container-main">

    <app-breadcrumb></app-breadcrumb>

    <div class="blogSearch">
      <h1><span>Blogs</span></h1>
      <div class="">
        <div class="form-group groupSortBy" style="display: none;">
          <label for="sortBy" class="form-label">Sort by:</label>
          <select class="dropIcon" id="sortBy" name="sortBy">
            <option>Select</option>
            <option>Default</option>
            <option>Newest</option>
            <option>Oldest</option>
          </select>
        </div>
        <div class="form-group">
          <input type="text" placeholder="Search Blogs Here..." (keyup)="onSearch($event)">
        </div>
      </div>
    </div>

    <div class="filter-wp" style="display: none;">
      <div class="collapse-panel">
        <div class="accordion" (click)="handleAccordionClick($event.currentTarget)">
          <a href="javascript:void(0)" role="button">
            <h2>Blog Category Filter</h2>
          </a>
        </div>
        <div class="panel">
          <div class="">
            <div class="">
              <div class="customcheckeBox">
                <input type="checkbox" name="category1" id="category1" />
                <label for="category1">Software Development (Team-as-service)</label>
              </div>
            </div>
            <div class="">
              <div class="customcheckeBox">
                <input type="checkbox" name="category2" id="category2" />
                <label for="category2">Digital Transformation</label>
              </div>
            </div>
            <div class="">
              <div class="customcheckeBox">
                <input type="checkbox" name="category3" id="category3" />
                <label for="category3">Artificial Intelligence and Machine Learning</label>
              </div>
            </div>
            <div class="">
              <div class="customcheckeBox">
                <input type="checkbox" name="category4" id="category4" />
                <label for="category4">Cloud Computing</label>
              </div>
            </div>
            <div class="">
              <div class="customcheckeBox">
                <input type="checkbox" name="category5" id="category5" />
                <label for="category5">Emerging Technologies</label>
              </div>
            </div>
            <div class="">
              <div class="customcheckeBox">
                <input type="checkbox" name="category6" id="category6" />
                <label for="category6">Business Process Improvement</label>
              </div>
            </div>
            <div class="">
              <div class="customcheckeBox">
                <input type="checkbox" name="category7" id="category7" />
                <label for="category7">IT Product Development and Architecture</label>
              </div>
            </div>
            <div class="">
              <div class="customcheckeBox">
                <input type="checkbox" name="category8" id="category8" />
                <label for="category8">Data-Driven Decision-Making</label>
              </div>
            </div>
            <div class="">
              <div class="customcheckeBox">
                <input type="checkbox" name="category9" id="category9" />
                <label for="category9">Leadership and Organizational Culture</label>
              </div>
            </div>
            <div class="">
              <div class="customcheckeBox">
                <input type="checkbox" name="category10" id="category10" />
                <label for="category10">Fintech (Financial Technology)</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row-flex">
      <ng-container *ngFor="let item of blogs">
        <div class="items-col-3">
          <div class="card-white">
            <p class="date">{{item.date|customdate}}</p>
            <h2>{{item.title}}</h2>
            <div class="">
              <p>{{item.description}}</p>
            </div>
            <div class="btn-fixed">
              <button type="button" [routerLink]="item.path" class="btn-blue" aria-label="Read Blog Post">Read Blog Post</button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>