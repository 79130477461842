import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CanonicalService } from './views/services-module/canonical.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Skillikz';
  schema: any;
  constructor(public http: HttpClient, private router: Router, private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.setJsonLdData();
    this.canonicalService.setCanonicalURL();
  }

  private setJsonLdData() {
    this.router.events.subscribe(routerEvt => {
      if (routerEvt instanceof NavigationEnd) {
        if (routerEvt.url == '/') {
          this.getDataHttp('assets/json-ld/home.json').subscribe(
            data => {
              this.schema = data;
            }
          );
        }
        else {
          let routerEvtUrl = routerEvt.url;
          // if (routerEvtUrl.includes('/blogs/'))
          //   routerEvtUrl = routerEvtUrl.replace('/blogs', '');
          this.getDataHttp('assets/json-ld' + routerEvtUrl + '.json').subscribe(
            data => {
              this.schema = data;
            }
          )
        }
      }
    })
  }

  getDataHttp(url: string) {
    return this.http.get(url);
  }
}
